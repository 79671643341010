import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import qs from 'qs'

import PATHS from 'constants/paths'

const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong. Please try again.'

axios.defaults.withCredentials = true

const transformRequest = (data: object, headers: object) => {
  // eslint-disable-next-line no-param-reassign
  headers['X-CSRF-Token'] = document.head
    .querySelector('meta[name=csrf-token]')
    ?.getAttribute('content')
  return decamelizeKeys(data)
}

const transformResponse = (data: object) => camelizeKeys(data)

const handleError = (error: {
  config?: { url?: string }
  response?: { data?: { error?: string }; status?: number }
}) => {
  const errorMessage = error.response?.data?.error || DEFAULT_ERROR_MESSAGE
  const status = error.response?.status

  // do not redirect to unauthorized if the request is to login
  if (status === 401 && error.config.url !== PATHS.loginPost) {
    window.location.href = PATHS.unauthorized
  }

  return Promise.reject(new Error(errorMessage))
}

const ApiService = axios.create({
  transformRequest: [
    transformRequest,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat([
    transformResponse,
  ]),
  paramsSerializer: (params) => qs.stringify(decamelizeKeys(params)),
})

ApiService.interceptors.response.use((response) => response, handleError)

export interface IndexResponseBase {
  meta: {
    page: number
    perPage: number
    total: number
  }
}

export default ApiService
