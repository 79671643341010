import { camelizeKeys } from 'humps'

export interface User {
  email?: string
  firstName?: string
  lastName?: string
  homePath: string
  organizationalUnitType: string
}

export interface IssuerConfig {
  helpDeskAvailableHours: string
  helpDeskPhoneNumber: string
  helpDeskPhoneNumberFormatted: string
  logoUrl: string
  paymentPhoneNumber: string
  paymentUrl: string
  summaryOfBenefitsUrl: string
  themeName?: string
}

export interface Issuer {
  name: string
  config?: IssuerConfig
}

interface ReportMenuItem {
  id: string
  label: string
  children: {
    id: string
    label: string
    value: string
  }[]
}

export interface AppConfig {
  user?: User | null
  issuer?: Issuer | null
  stripePublicKey?: string | null
  reportsMenu?: ReportMenuItem[]
  organizationalUnitType?: string
}

declare const window: {
  appConfig: AppConfig
} & Window

const getAppConfig = (): AppConfig => {
  const config = window.appConfig || {}
  delete window.appConfig
  const dataScript = document.getElementById('react-config')
  dataScript.remove()
  return camelizeKeys(config)
}

export default getAppConfig
