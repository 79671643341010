import { Button, Checkbox, TextField } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import React from 'react'

import loginApi from 'apis/sessions/loginApi'
import Redirect from 'components/shared/Redirect'
import useForm, { Validations } from 'hooks/useForm'
import usePromise from 'hooks/usePromise'
import { emailValidator, presenceValidator } from 'utils/validators'

const initialValues = {
  email: '',
  password: '',
  rememberMe: false,
}

const validations: Validations<typeof initialValues> = {
  email: [
    { validator: presenceValidator, message: 'Email is missing' },
    { validator: emailValidator, message: 'Email is not valid' },
  ],
  password: [{ validator: presenceValidator, message: 'Password is missing' }],
  rememberMe: [],
}

const Form = () => {
  const { textFieldProps, checkboxProps, handleFormSubmit } = useForm(initialValues, {
    validations,
  })
  const [{ status, error, response }, loginAction] = usePromise(loginApi.login)

  if (status.isResolved) {
    const path = response?.data?.user?.homePath || '/'
    return <Redirect to={path} replace />
  }

  return (
    <Box maxWidth="750px" margin="1em auto" component={Paper}>
      <form onSubmit={handleFormSubmit(loginAction)}>
        <Stack padding={2} spacing={2}>
          <TextField label="Email" {...textFieldProps('email')} />
          <TextField label="Password" type="password" {...textFieldProps('password')} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox {...checkboxProps('rememberMe')} />}
              label="Remember me"
            />
          </FormGroup>
          <Button type="submit" variant="contained" disabled={status.isPending}>
            Login
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </form>
    </Box>
  )
}

export default Form
