import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'

import Redirect from 'components/shared/Redirect'
import useAppConfig from 'hooks/useAppConfig'

import Form from './Form'

const Login = () => {
  const { user, organizationalUnitType } = useAppConfig()

  if (user) return <Redirect to={user.homePath || '/'} replace />

  if (organizationalUnitType === 'member') {
    return (
      <Box maxWidth="750px" margin="1em auto" component={Paper} sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Looks like you’re not logged in yet.
        </Typography>
        <Typography variant="body1">
          Please log in through your member online portal to access this page.
        </Typography>
      </Box>
    )
  }

  return <Form />
}

export default Login
